import React, { Component } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { CSSTransitionGroup } from 'react-transition-group';
import '../animation.css';

class Video extends Component {
	state = {
        slide: false
    }

    componentDidMount() {
    	this.setState({slide: true})
    }
	

	leftslideCustom = () => {
		if(this.state.slide) {
			return (
                <div className="gray-bandeau col-lg-6 col-md-8 parallax">
					<div className="col-md-11 p-0">
						<p className="m-0">{ this.props.findTitle(this.props.section).title }</p>
						<p className="description">{ this.props.findTitle(this.props.section).description }</p>
					</div>
				</div>
            )
		} else {
			return (null)
		}
	}

	rightslideCustom = () => {
		if(this.state.slide) {
			return (
				<div className="green-bandeau col-lg-5 col-md-8 float-right parallax mt-lg-5 mt-5 mt-lg-0">
					<p className="m-0">{ this.props.findTitle(this.props.section).name }</p>
				</div>
            )
		} else {
			return (null)
		}
	}
	render() {
		return (
			<div className="container-fluid video p-0 position-relative">
				<video className="img-fit col-12 p-0" autoPlay loop muted playsInline>
					<source type="video/mp4" src="/img/video.mp4" />
					<source type="video/webm" src="/img/video.webm" />
				</video>
				<div className="overlay"></div>
				<div className="position-absolute title_absolute container">
					<CSSTransitionGroup
					transitionName="leftSlide"
					transitionEnterTimeout={3000}
					transitionLeaveTimeout={3000}
					>
						{ this.leftslideCustom() }
					</CSSTransitionGroup>
					<div className="container p-0 mt-lg-5 mt-3 parallax">
						<div className="col-md-6">
							<AnchorLink href="#resa" offset='100' className="btnPerso">Réservez maintenant !</AnchorLink>
						</div>
					</div>
					<CSSTransitionGroup
					transitionName="rightSlide"
					transitionEnterTimeout={3000}
					transitionLeaveTimeout={3000}
					>
						{ this.rightslideCustom() }
					</CSSTransitionGroup>
				</div>	
				<div className="position-absolute scroll_icon d-none d-lg-block">
					<AnchorLink href="#about" offset='100' className="anchor-scroll">
						<img src="/img/icon/scroll.svg" alt="scroll"/>
					</AnchorLink>
				</div>
			</div>
		)
	}
}

export default Video;
