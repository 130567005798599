import axios from 'axios';
import bodymovinjs from 'bodymovin';

bodymovinjs.loadAnimation({
  container: document.getElementById('bm'),
  renderer: 'svg',
  loop: false,
  autoplay: false,
  path: 'data.json',
  setQuality: 'low'
})

axios.interceptors.request.use(function (config) { 

  if(!config.url.includes("contact")){
    
    bodymovinjs.play()

    document.getElementById('loader').classList.remove('d-none');
    document.getElementById('loader').classList.add('d-flex');    
  }

  return config

}, function (error) {
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) { 
  document.getElementById('loader').classList.remove('d-flex');
  document.getElementById('loader').classList.add('d-none');

  bodymovinjs.stop()

  return response;
}, function (error) {
  // window.location = "/";
  //return Promise.reject(error);
  console.log(error)
});

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
}