import React, { Component } from "react";
import ShortTitle from "./short-title";
import { formatNumber } from "../utils/noName";

class GroundProduct extends Component {
	render() {
		return (	
			<div>
				<div className="ground-product">
					<ShortTitle plot={this.props.plot} properties={this.props.propertiesPlot} />
					<div className="carousel slide" id="carouselControls" data-ride="carousel">
						<div className="align-items-center justify-content-between d-none d-lg-flex">
							<div className="nav_custom position-relative mt-5 d-none d-lg-block">
								<a className="carousel-control-prev" href="#carouselControls" role="button" data-slide="prev">
									<span className="carousel-control-prev-icon" aria-hidden="true"></span>
									<span className="sr-only">Previous</span>
								</a>
								<a className="carousel-control-next" href="#carouselControls" role="button" data-slide="next">
									<span className="carousel-control-next-icon" aria-hidden="true"></span>
									<span className="sr-only">Next</span>
								</a>
							</div>
							<div className="position-relative nav-container align-items-center d-none d-lg-flex">
								<ol className="carousel-indicators-perso mb-4">
									<div className="carousel-indicators m-0 d-flex justify-content-end">
									{this.props.propertiesPlot ? this.props.propertiesPlot.map((property, key) => {
										return (
											<img key={key} src={property.cover} alt={property.name} data-target="#carouselControls" data-slide-to={key} className={`cover ${key === 0 ? "active" : "ml-2"}`} />
										)
									}) : (
										<p>Pas de modèle pour ce terrain</p>
									)}
									</div>
								</ol>
							</div>
						</div>
						<div className="carousel-inner">
						{this.props.propertiesPlot ? this.props.propertiesPlot.map((property, key) => {
								return (
									<div key={key} className={`carousel-item ${key === 0 ? "active" : ""}`}>
										<div  className="row m-0 mt-5 p-0">
											<div className="col-lg-5 p-0">
												<div className="mb-3">
													<p className="mb-1 font_light">Modèle</p>
													<p><strong>{property.name}</strong></p>
												</div>
												<div className="mb-3">
													<p className="mb-1 font_light">Prix</p>
													<p className="m-0"><strong>{ formatNumber(Math.round((property.price))) } €  TTC</strong></p>
													<p><small>(dont TVA 20% : {formatNumber(Math.round((property.price * 20 / 100)))} € )</small></p>
												</div>
												<div className="mb-5">
													<p className="mb-1 font_light">Description</p>
													<p className="descri" dangerouslySetInnerHTML={{ __html: property.description }}></p>
												</div>
											</div>
											<div className="col-lg-6 offset-lg-1 img p-0">
												<img src={property.cover} alt={property.name} />
											</div>
										</div>
									</div>
								)
							}) : (
								<p>Pas de modèle pour ce terrain</p>
						)}
						</div>
						<div className="position-relative nav-container d-flex align-items-center d-block d-lg-none">
							<ol className="carousel-indicators-perso mt-2">
								<div className="carousel-indicators m-0 d-block">
								{this.props.propertiesPlot ? this.props.propertiesPlot.map((property, key) => {
									return (
										<img key={key} src={property.cover} alt={property.name} data-target="#carouselControls" data-slide-to={key} className={`cover ${key === 0 ? "active" : "ml-2"}`} />
									)
								}) : (
									<p>Pas de modèle pour ce terrain</p>
								)}
								</div>
							</ol>
						</div>					
					</div>				
				</div>				
			</div>	
		)
	}
}

export default GroundProduct;