import React, { Component } from "react";
import Title from "./title";

class Partners extends Component {
	render() {
		return (
			<div className="partners container padd">
				<div className="col-lg-5 text-center mx-auto section_title">
					<Title
						name={this.props.findTitle(this.props.section).name}
						title={this.props.findTitle(this.props.section).title}
						description={this.props.findTitle(this.props.section).description}
					/>
				</div>
				<div className="row align-items-center m-0">
					<div  className="col-lg-3 col-6 mb-5 text-center appear">
						<a href="https://www.plurialhomeexpert.fr" target="_blank" rel="noopener noreferrer">
							<img className="plurialLogo" src="/img/logo-phe-white.png" title="Plurial Home Expert" alt="Plurial Home Expert" />
						</a>
					</div>
					<div  className="col-lg-3 col-6 mb-5 text-center appear">
						<a href="https://berdin.immo" target="_blank" rel="noopener noreferrer">
							<img src="/img/logo_berdin.png" title="Berdin" alt="Berdin" />
						</a>
					</div>
					<div  className="col-lg-3 col-6 mb-5 text-center appear">
						<a href="https://reims.maison-natilia.fr" target="_blank" rel="noopener noreferrer">
							<img src="/img/logo_natilia.png" title="Natilia" alt="Natilia" />
						</a>
					</div>
					<div  className="col-lg-3 col-6 mb-5 text-center appear">
						<a href="https://reims.villas-club.fr" target="_blank" rel="noopener noreferrer">
							<img src="/img/logo_villasclub.png" title="Villas Club" alt="Villas Club" />
						</a>
					</div>
				</div>
			</div>
		)
	}
}

export default Partners;