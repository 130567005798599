import React, { Component } from "react";
import Title from "./title";
import SimpleMap from "./google-map";

class ProjectMap extends Component {
	render() {
		return (
			<div className="container">
				<div className="col-lg-10 mx-auto project">
					<div className={`row item ${this.props.direction}`}>
						<div className="col-lg-6 mb-5 mb-xl-0 text-center">
							<SimpleMap />
						</div>
						<div className="col-lg-1"></div>
						<div className="col-lg-5 col-md-7 col-12 col-sm-10 p-lg-3 mx-auto d-flex align-items-center">
							<Title
								hastitle={this.props.hastitle}
								name={this.props.findTitle(this.props.section).name}
								title={this.props.findTitle(this.props.section).title}
								description={this.props.findTitle(this.props.section).description}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProjectMap;