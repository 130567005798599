import React, { Component } from "react";
import axios from "axios";

import { ProjectProvider, ProjectConsumer } from './contexts/ProjectContext'

import Header from './components/header';
// import Map from './components/map';
import Title from "./components/title";
import ModelProduct from "./components/model-product";
import FormResa from "./components/form-resa";
import Credit from "./components/credit";

import CollabCard from "./components/collab-card";

import Helmet from "react-helmet";

class SinglePlot extends Component {

    state = {
        property: {
            name: "",
            size: "",
            company: {
                name: ""
            }
        }
    }

    componentDidMount() {
        window.scrollTo(0,0);
        axios.get(
            `${this.props.BASEURL}properties/${this.props.match.params.slug}`,
        ).then(res => {
            this.setState({property: res.data.property})
        })
    }
    
    render() {
        return (
            <ProjectProvider>
                <Helmet>
					<title>{ `Modèle ${this.state.property.name} | Le Parc Montepillois Cormontreuil` }</title>
					<meta name="description" content="" />
				</Helmet>
                <ProjectConsumer>
                    {({ findTitle, findPlot, plots, selectedPlot }) => {
                        return (
                            <section className="position-relative" id="top">
                                <Header 
                                    title={`${this.state.property.company.name} - ${this.state.property.name}`}                                    
                                    page="resa"
                                    color="dark"
                                    position="" 
                                    back="#houses"
                                />
                                <div className="container resa">
                                    <section className="col-lg-4 mx-auto text-center title_resa padd40 section_title">
                                        <Title
                                            name={`${this.state.property.company.name}`}
                                            hastitle=""
                                            title={`${this.state.property.name}`}
                                            description={`Découvrez ce modèle de ${this.state.property.size} m2`}
                                        />
                                    </section>
                                    <hr />
                                    <section className="p-0">
                                        <ModelProduct 
                                            property={this.state.property}
                                        />
                                    </section>
                                    {/* <hr />
                                    <section>
                                        <Map 
                                            findTitle={findTitle}
                                            position="right"
                                            plots={plots}
                                            findPlot={findPlot}
                                            selectedPlot={selectedPlot}
                                            section="map"
                                        />                        
                                    </section> */}
                                    <hr />
                                    <section className="p-0 col-lg-12 mx-auto section_title" id="contact">
                                        <div className="text-center padd40">
                                            <Title							
                                                name={ this.props.findTitle("from_property").name }
                                                title={ this.props.findTitle("from_property").title }
                                                description={ this.props.findTitle("from_property").description }
                                            />
                                        </div>
                                        <div className="row mb-5">
                                            <div className="col-lg-4 d-flex justify-content-center pl-md-3 padd40">
                                                <CollabCard 
                                                    logo={ this.state.property.company.logo}
                                                    name={ this.state.property.company.name }
                                                    description={ this.state.property.company.description }
                                                    site={ this.state.property.company.site }
                                                    phone={ this.state.property.company.phone }
                                                    gaUrl="clic-telephone-modele"
                                                />
                                            </div>
                                            <div className="col-lg-8">
                                                <FormResa 
                                                    BASEURL={ this.props.BASEURL } 
                                                    contactForm={ this.props.contactForm }
                                                    msgSend={ this.props.msgSend }
                                                    msgInSending={ this.props.msgInSending } 
                                                    company={ this.state.property.company }
                                                />
                                            </div>
                                        </div>
                                    </section>
                                </div>

                                <section id="credit">
                                    <Credit />
                                </section>
                            </section>
                        )}
                    }
                </ProjectConsumer>
            </ProjectProvider>
        )
    }
}

export default SinglePlot;