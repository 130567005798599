import React, { Component } from "react";

import Header from './components/header';
import Credit from "./components/credit";

class NoMatch extends Component {
    
    componentWillMount() {
        // window.location = "/manager/index.html"
    }
    
    render() {
        return (
            <section>
                <Header 
                    title="Home" 
                    page="resa"
                    color="dark"
                    position="" 
                />
                <div className="container resa">
                    <section className="col-lg-4 mx-auto text-center title_resa padd40 section_title">
                        <h1>404</h1>
                    </section>
                </div>

                <section id="credit">
                    <Credit />
                </section>
            </section>
        )
    }
}

export default NoMatch;