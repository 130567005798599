import 'core-js/';
import 'classlist-polyfill';

import React from 'react';
import { hydrate, render } from "react-dom";

import Front from './Front';
import SinglePlot from './Front/single-plot';
import SingleProperty from './Front/single-property';
import NoMatch from "./Front/NoMatch";

import './Front/utils/noName';

import * as serviceWorker from './serviceWorker';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ProjectProvider, ProjectConsumer } from './Front/contexts/ProjectContext';

import ReactGA from 'react-ga';

ReactGA.initialize('UA-133787-31', { debug: false });
ReactGA.pageview(window.location.pathname + window.location.search);


const Index = () => (
    <Router>
        <Switch>
          <Route exact path="/" component={ Front } />          
          <Route exact path="/manager" component={ NoMatch } status={ 301 } />          
          <ProjectProvider>
            <ProjectConsumer>
              {({ BASEURL, findTitle, contactForm, msgSend, msgInSending }) => {
                  return (
                    <div>
                      <Route path="/terrains/:slug" component={(props) => <SinglePlot 
                        {...props} 
                        BASEURL={ BASEURL }
                        contactForm={ contactForm }
                        msgSend={ msgSend }
                        msgInSending={ msgInSending } 
                        findTitle={ findTitle } />} />
                      <Route path="/biens/:slug"  component={(props) => <SingleProperty 
                        {...props}
                        BASEURL={ BASEURL }
                        contactForm={ contactForm }
                        msgSend={ msgSend }
                        msgInSending={ msgInSending } 
                        findTitle={ findTitle } />} />
                    </div>
                  )
              }}
            </ProjectConsumer>
          </ProjectProvider>
          <Route component={ NoMatch } status={ 404 } /> 
        </Switch>
    </Router>
)




const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<Index />, rootElement);
} else {
  render(<Index />, rootElement);
}

// ReactDOM.render(<Index />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
