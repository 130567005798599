import React, { Component } from 'react';
import {Modal, Button} from 'react-bootstrap';


class Credit extends Component {
    constructor(props, context) {
        super(props, context);
    
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    
        this.state = {
          show: false,
          cookie: false,
          cookieClass: ""
        };
      }
    
      componentDidMount() {
        let cookie = localStorage.getItem('cookie') || null;
        this.setState({
          cookie: cookie,
          cookieClass: cookie ? "d-none" : null
        })
      }

      handleOk() {
        let cookie = localStorage.setItem('cookie', true);
        this.setState({
          cookie: cookie,
          cookieClass: "d-none"
        })
      }

      handleClose() {
        this.setState({ show: false });
      }
    
      handleShow(event) {
        event.preventDefault()
        this.setState({ show: true });
      }
    
      render() {
      return (
        <div>

          <section className="credit container-fluid">
            <div className="container"> 
              <p className="m-0 text-center">© <strong>2018</strong>- Tous droits réservés à Plurial Novilia -  Le Parc Montepillois - <span className="pointer" onClick={ this.handleShow } >Mentions légales</span></p>
            </div>
          </section>

          <section className="credit_bis container-fluid">
            <div className="container"> 
              <p className="m-0 text-center">Le site internet <a href="https://parc-montepillois-cormontreuil.fr">http://parc-montepillois-cormontreuil.fr</a> est une œuvre de l’esprit protégée par la législation en vigueur.</p>
            </div>
          </section>
          
          <section className={ `cookie container-fluid row m-0 ${this.state.cookieClass}` }>
            <div className="col-lg-11 d-flex align-items-center">
                <p className="m-0 text-left">En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour vous proposer la meilleure expérience de navigation possible, réaliser des statistiques de visites et vous permettre de partager certains contenus de notre site. <span className="pointer" onClick={ this.handleShow } >En savoir plus</span></p>
            </div>
            <div className="col-lg-1 mt-2 mt-md-0 text-right">
                <button onClick={ this.handleOk.bind(this) } onTouchStart={ this.handleOk.bind(this) } className="btnPerso">Ok</button>
            </div>
          </section>
          

          <Modal animation={ false } bsSize="large" autoFocus={ true } show={ this.state.show } onHide={ this.handleClose }>
            <Modal.Header closeButton>              
            </Modal.Header>
            <Modal.Body>
              <h3>Mentions légales</h3>
              <p>Le site internet <strong><a href="https://parc-montepillois-cormontreuil.fr">https://parc-montepillois-cormontreuil.fr</a></strong> est une œuvre de l’esprit protégée par la législation en vigueur.</p><br />
              <p>Crédits photos : Duo Motion, Madara.</p><br />
              <strong>Mentions légales</strong>

              <p>Propriété du site<br />
              Conformément aux dispositions de l’article 6 III-1 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous vous informons que le présent site est la propriété pleine et entière de <a href="https://www.plurial-novilia.fr">Plurial Novilia</a>.<br />
              <strong>Directeur de la publication</strong> : M. Alain NICOLE, Directeur Général<br />
              <strong>Réalisation</strong> : <a title="Agence de création digitale" href="https://www.madara.io">MADARA</a><br />
              <strong>Webdesign</strong> : <a title="Agence de création digitale" href="https://www.madara.io">MADARA</a><br />
              <strong>Hébergement</strong> : Digital Ocean pour le Backend et OVH pour le React<br /><br />

              <strong><a href="https://www.plurial-novilia.fr">Plurial Novilia</a></strong>, en qualité de Responsable de Traitement, collecte des données à caractère personnel via son site internet et met en œuvre des traitements automatisés de ces données qui sont intégrés dans la liste de traitements de son Délégué à la Protection des données.<br /><br />

              Les informations qui sont collectées sur ce site permettent à Plurial Novilia et à ses partenaires, Natilia, Villas Club, Berdin Immobilier et Plurial Home Expert, la gestion et le suivi des démarches réalisées en ligne par l’utilisateur et sont traitées de manière loyale, confidentielle et de façon sécurisée. Plurial Novilia, Plurial Home Expert, Natilia, Villas Club, Berdin Immobilier sont les seuls destinataires de ces données.<br /><br />

              Les données personnelles ne sont pas conservées au-delà de la durée nécessaire à la réalisation de la finalité poursuivie et ce conformément à la législation sur la protection des données personnelles. Elles peuvent faire l’objet d’un archivage pour répondre à une obligation légale ou règlementaire et aux autorités de contrôle de la profession.<br /><br />

              Les mentions légales en matière de protection des données personnelles sont rappelées en bas des formulaires de collecte de données.<br /><br />

              Conformément aux règlementations en vigueur en matière de protection des données et notamment aux dispositions du Règlement (UE) n° 2016/679 et de la Loi 78-17 du 6 Janvier modifiée relative à l’informatique, aux fichiers et aux libertés, l’utilisateur dispose d’un droit d’accès, de rectification, de limitation ou d’effacement des informations le concernant et peut s’opposer au traitement de ses données personnelles.<br /><br />

              L’utilisateur peut exercer son droit d’accès aux données qui le concernent en justifiant de son identité et en contactant le Délégué à la Protection des Données (DPO) de <a href="https://www.plurial-novilia.fr">Plurial Novilia</a> par voie électronique : <a href="mailto:plurial-novilia.dpo@plurial.fr">plurial-novilia.dpo@plurial.fr</a> ou par courrier postal <a href="https://www.plurial-novilia.fr">Plurial Novilia</a> – DPO 2 Place Paul Jamot – CS80017 -51723 REIMS. Sous réserve d’un manquement aux dispositions ci-dessus, l’utilisateur a le droit d’introduire une réclamation auprès de la CNIL.<br /><br />

              Les utilisateurs du site sont tenus de leur côté de respecter les dispositions de la loi « Informatique et Libertés », dont la violation est passible de sanctions pénales.<br /><br />

              <strong>Cookies</strong><br />
              Lorsque vous naviguez sur notre site internet, des informations sont susceptibles d'être enregistrées, ou lues, dans votre terminal. Ces cookies ne permettent pas de vous identifier mais enregistrent des informations relatives à votre navigation sur notre site afin d'établir des statistiques de fréquentation (nombre de visites, pages vues, etc.), de vous proposer la meilleure expérience de navigation possible et vous permettre de partager certains contenus de notre site.<br /><br />

              <strong>Google Analytics</strong><br />
              Ce site utilise Google Analytics, un service d'analyse de site Internet fourni par Google Inc. (« Google »). Google Analytics utilise des cookies, qui sont des fichiers texte placés sur votre terminal, pour nous aider à analyser l'utilisation que vous faites de notre site. Les données générées par les cookies concernant votre utilisation du site seront transmises et stockées par Google sur des serveurs situés aux États-Unis. Google utilisera cette information dans le but d'évaluer votre utilisation du site, de compiler des rapports sur l'activité du site à destination de son éditeur et de fournir d'autres services relatifs à l'activité du site et à l'utilisation d'Internet. Google est susceptible de communiquer ces données à des tiers en cas d'obligation légale ou lorsque ces tiers traitent ces données pour le compte de Google, y compris notamment l'éditeur de ce site. Google ne recoupera pas votre adresse IP avec toute autre donnée détenue par Google.<br />
              Nous avons activé la fonction "anonymisation de l'IP" sur notre site : votre adresse IP est toutefois raccourcie auparavant par Google dans les pays membres de l’Union Européenne ou dans d’autres pays parties à l’Accord sur l’Espace économique européen.<br />
              En utilisant ce site Internet, vous consentez expressément au traitement de vos données nominatives par Google dans les conditions et pour les finalités décrites ci-dessus.<br /><br />

              <strong>Cookies tiers</strong><br />
              Les cookies tiers sont des cookies déposés sur votre terminal par des sociétés tierces (par exemple des partenaires) lorsque vous naviguez sur notre site. Dans le cadre de partenariats, nous veillons à ce que les sociétés partenaires respectent strictement la loi « Informatique et Libertés » et le RGPD.<br />
              Nous sommes susceptibles d'inclure sur notre site, des applications informatiques émanant de tiers, qui vous permettent de partager des contenus de notre site avec d'autres personnes ou de faire connaître à ces autres personnes votre consultation ou votre opinion concernant un contenu de notre site. Tel est notamment le cas des boutons "Partager", "J'aime", issus de réseaux sociaux tels que Facebook "Twitter", LinkedIn", "Viadeo", etc. même si vous n'avez pas utilisé ce bouton lors de votre consultation de notre site. En effet, ce type de bouton applicatif peut permettre au réseau social concerné de suivre votre navigation sur notre site, du seul fait que votre compte au réseau social concerné était activé sur votre terminal (session ouverte) durant votre navigation sur notre site. Nous n'avons aucun contrôle sur le processus employé par les réseaux sociaux pour collecter des informations relatives à votre navigation sur notre site et associées aux données personnelles dont ils disposent. Nous vous invitons à consulter les politiques de protection de la vie privée de ces réseaux sociaux afin de prendre connaissance des finalités d'utilisation.<br /><br />

              <strong>AddThis</strong><br />
              Ce site utilise Ce site utilise des cookies de mesure d’audience AddThis afin d’analyser le fonctionnement du site et mesurer les actions de partage sur les réseaux sociaux.<br /><br />

              <strong>Vos choix concernant les cookies</strong><br />
              Plusieurs possibilités vous sont offertes pour gérer les cookies. Tout paramétrage que vous pouvez entreprendre sera susceptible de modifier votre navigation sur Internet et vos conditions d’accès à certains services nécessitant l’utilisation de Cookies. Vous pouvez exprimer et modifier à tout moment vos souhaits en matière de cookies, par les moyens décrits ci-dessous.<br /><br />

              <strong>Paramétrage de votre navigateur</strong><br />
              Google Chrome<br />
              Firefox<br />
              Internet Explorer<br />

              Opt-out pour les cookies de mesure d'audience : Google Analytics<br />
              Pour plus d’informations sur les cookies, vous pouvez consulter le site de la CNIL.<br /><br />

              <strong>Respect du droit d'auteur</strong><br />
              Les dessins, photographies, images, textes, séquences animées sonores ou non, et autres documentations représentées sur le présent site Internet sont objet de droits de propriété industrielle et/ou intellectuelle, propriétés de <a href="https://www.plurial-novilia.fr">Plurial Novilia</a>, <a href="https://reims.maison-natilia.fr">Natilia</a>, <a href="https://reims.villas-club.fr">Villas Club</a>, <a href="https://berdin.immo">Berdin Imobilier</a> et <a href="https://www.plurialhomeexpert.fr">Plurial Home Expert</a>. A ce titre, toute reproduction, représentation, adaptation, traduction, et/ou transformation, partielle ou intégrale, ou transfert sur un autre site sont interdits.<br /><br />

              La copie à usage privé de ces différents objets de droits est autorisée moyennant mention de la source. Leur reproduction partielle ou intégrale, sans l’accord préalable et écrit est strictement interdite.<br /><br />

              Les renseignements et présentations contenus dans les pages de ce site sont fournis à titre purement indicatif et restent susceptibles de modifications à tout moment et sans préavis. Leur utilisation à des fins privées ou professionnelles ne saurait engager la responsabilité de <a href="https://www.plurial-novilia.fr">Plurial Novilia</a>.<br /><br /></p>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={ this.handleClose }>Fermer</Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }
  }

  export default Credit;