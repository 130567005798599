import React, { Component } from "react";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { formatNumber } from "../utils/noName";

class ModelProduct extends Component {
    render() {
        return (
            <div className="model-product padd40">
                <div className="row m-0 p-0">
                    <div className="col-lg-5 p-0">
                        <img className="logo" src={this.props.property.company.logo} alt={this.props.property.company.name} />
                        <div className="mb-3">
                            <p className="mb-1 font_light">Modèle</p>
                            <p><strong>{this.props.property.name}</strong></p>
                        </div>
                        <div className="mb-3">
                            <p className="mb-1 font_light">Prix</p>
                            <p className="m-0"><strong>à partir de {formatNumber(Math.round((this.props.property.price)))} €  TTC</strong></p>
                            <p><small>(dont TVA 20% : {formatNumber(Math.round((this.props.property.price * 20 / 100)))} € )</small></p>                            
                        </div>
                        <div className="mb-5">
                            <p className="mb-1 font_light">Description</p>
                            <p className="descri" dangerouslySetInnerHTML={{ __html: this.props.property.description }}></p>
                        </div>
                    </div>
                    <div className="col-lg-6 offset-lg-1 img p-0">
                        <img src={this.props.property.cover} alt={this.props.property.name} />
                    </div>
                    <div className="mx-auto mt-5 scroll_icon d-none d-lg-block">
                        <AnchorLink href="#contact" offset='100' className="anchor-scroll">
                            <img src="/img/icon/scroll-green.svg" alt=""/>
                        </AnchorLink>
                    </div>
                </div>
            </div>
        )
    }
}

export default ModelProduct;