import React, { Component } from "react";
import axios from "axios";
import Header from './components/header';
import Title from "./components/title";
import ResaCard from "./components/resa-card";
import FormResa from "./components/form-resa";
import GroundProduct from "./components/ground-product";
import Credit from "./components/credit";
import AnchorLink from 'react-anchor-link-smooth-scroll';
import CollabCard from "./components/collab-card";

import Helmet from 'react-helmet';

class SinglePlot extends Component {

    state = {
        plot: {
            company: {
                name: "",
                logo: ""
            },
            properties: [],
            state: {
                name: ""
            }
        }
    }
    
    componentDidMount() {
        window.scrollTo(0,0);
        axios.get(
            `${this.props.BASEURL}plots/${this.props.match.params.slug}`,
        ).then(res => {
            this.setState({plot: res.data.plot})
        })
    }
    
    render() {
        return (
            <section className="position-relative" id="top">
                <Helmet>
					<title>{ `Terrain de ${this.state.plot.size}m2 | Le Parc Montepillois Cormontreuil` }</title>
					<meta name="description" content={ `Réserver ce terrain de ${this.state.plot.size}m2, et découvrez les modèles de maisons proposés pour cette parcelle.` } />
				</Helmet>
                
                <Header 
                    title="Home" 
                    page="resa"
                    color="dark"
                    position="" 
                    back="#resa"
                />
                <div className="container resa">
                    <section className="col-lg-4 mx-auto text-center title_resa padd40 section_title">
                        <Title
                            name={`${this.state.plot.company.name}`}
                            hastitle=""
                            title={`${this.state.plot.name} n°${this.state.plot.number}`}
                            description={`Réserver ce terrain de ${this.state.plot.size}m2, et découvrez les modèles de maisons proposés pour cette parcelle.`}
                        />
                    </section>
                    <hr />
                    <section className="row m-0 padd40">
                        <div className="col-lg-3 p-0 padd mb-5">
                            <ResaCard btn={false} plot={this.state.plot} />
                        </div>
                        <div className="col-lg-8 offset-lg-1">
                            <GroundProduct propertiesPlot={this.state.plot.properties} plot={this.state.plot} />
                            <p className="mt-5 mt-lg-1 mb-md-5">Contactez-nous pour découvrir l'ensemble de notre gamme.</p>                          
                        </div>
                        <div className="mx-auto scroll_icon d-none d-lg-block">
                            <AnchorLink href="#contact" offset='100' className="anchor-scroll">
                                <img src="/img/icon/scroll-green.svg" alt=""/>
                            </AnchorLink>
                        </div>
                    </section>
                    <hr />
                    <section className="p-0 col-lg-12 mx-auto section_title" id="contact">
                        <div className="text-center padd40">
                            <Title							
                                name={ this.props.findTitle("from_plot").name }
                                title={ this.props.findTitle("from_plot").title }
                                description={ this.props.findTitle("from_plot").description }
                            />
                        </div>
                        <div className="row mb-5">
                            <div className="col-lg-4 d-flex justify-content-center pl-md-3 padd40">
                                <CollabCard 
                                    logo={ this.state.plot.company.logo}
                                    name={ this.state.plot.company.name }
                                    description={ this.state.plot.company.description }
                                    site={ this.state.plot.company.site }
                                    phone={ this.state.plot.company.phone }
                                    gaUrl="clic-telephone-Terrain"
                                />
                            </div>
                            <div className="col-lg-8">
                                {this.state.plot.number ? (
                                    <FormResa 
                                        BASEURL={ this.props.BASEURL } 
                                        contactForm={ this.props.contactForm }
                                        msgSend={ this.props.msgSend }
                                        msgInSending={ this.props.msgInSending } 
                                        plot={ this.state.plot }
                                    />
                                ) : (null)}
                            </div>
                        </div>
                    </section>
                </div>

                <section id="credit">
                    <Credit />
                </section>
            </section>
        )
    }
}

export default SinglePlot;