import React, { Component } from "react";
import { HashLink as Link } from 'react-router-hash-link';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Header extends Component {
    render() {
		const scrollWithOffset = (el, offset) => {
			const elementPosition = el.offsetTop - offset;
			window.scroll({
			  top: elementPosition,
			  left: 0,
			  behavior: "smooth"
			});
		}
		return (
			<div className={`${this.props.page}`}>
				<header className={`header ${this.props.position} ${this.props.color} shadow_custom`} >
					<div className="container position-relative">
						<div className="logo">
							{this.props.page === "home" ? (
								<div>
									<AnchorLink href="#top" className="anchor-scroll logo-bw desktop"><img src="./img/logo.svg" alt="Le Parc Montepillois" /></AnchorLink>
									<AnchorLink href="#top" className="anchor-scroll logo-color d-none desktop"><img src="./img/logo-color.svg" alt="Le Parc Montepillois" /></AnchorLink>
									<AnchorLink href="#top" className="anchor-scroll logo-bw mobile"><img src="./img/logo-m-white.svg" alt="Le Parc Montepillois" /></AnchorLink>
									<AnchorLink href="#top" className="anchor-scroll logo-color d-none mobile"><img src="./img/logo-m-color.svg" alt="Le Parc Montepillois" /></AnchorLink>
								</div>
								) : (
								<div>
									<Link to="/" className="anchor-scroll"><img src="/img/logo-color.svg" alt="Le Parc Montepillois" /></Link>
								</div>
								
							)}
						</div>
						<nav className="row m-0 justify-content-between align-items-center col-12 navContainer">
							
							{this.props.page === "home" ? (
								// <a className="open_menu"><i className="icon-menu mr-3"></i>Menu</Link>
								<span className="open_menu" onClick={ this.props.toggleMenu }><i className="icon-menu mr-3"></i><span className="d-none d-lg-inline">Menu</span></span>
							) : (
								<Link to={`/${this.props.back}`} scroll={el => scrollWithOffset(el, -700)}><i className="icon-back mr-3"></i><span className="d-none d-lg-inline">Retour</span></Link>
							)}
							<div className="row m-0">
								<AnchorLink href="#contact" offset='100' className="mr-lg-5 "><i className="icon-email mr-3"></i><span className="d-none d-lg-inline">Contact</span></AnchorLink>				    		
							</div>
						</nav>
					</div>
				</header>
			</div>
		)
	}
}

export default Header;