import React, { Component } from "react";
import {Alert} from "react-bootstrap"
import ReactGA from 'react-ga';

class FormOne extends Component {
	
	handleSubmit(event) {
		event.preventDefault();

		ReactGA.ga('send', 'pageview', '/demande-de-contact-ok');

		const form = event.target;
    	const data = new FormData(form);

		this.props.contactForm(data)
		this.contactForm.reset();
	}
	
	render() {
		return (
			<div className="form_one">
				{ this.props.msgSend ? 
					(<Alert bsStyle="success">
						Votre message a bien été envoyé aux équipes. Nous revenons vers vous très prochainement.
					</Alert> ) : (null) }
				<form 
					onSubmit={ this.handleSubmit.bind(this) }
					ref={(el) => this.contactForm = el}
				>
				
					<div className="form-row">
						<div className="form-group col-md-6">
							<input type="text" className="form-control" required name="firstname" placeholder="Prénom *" />
						</div>
						<div className="form-group col-md-6">
							<input type="text" className="form-control" required name="lastname" placeholder="Nom *" />
						</div>
					</div>
					<div className="form-group">
						<input type="email" className="form-control" required name="email" placeholder="Email *" />
					</div>
					<div className="form-group">
						<input type="text" className="form-control" name="phone" placeholder="Téléphone" />
					</div>
					<div className="form-group">
						<select name="CompanyID" required className="form-control">
							<option value="" disabled selected>Constructeurs *</option>
							{ this.props.companies.map( (company, key) => {
								return (<option value={ company.ID } key={ key }>{ company.name }</option>)
							}) }
						</select>
					  </div>
					<div className="form-group">
						<textarea className="form-control" name="message" placeholder="Message" rows="6"></textarea>
					</div>
					<div className="custom-control custom-checkbox mb-2">
						<input id="policy" required type="checkbox" className="custom-control-input" name="policy"/>
						<label className="custom-control-label policy" htmlFor="policy">J’accepte que les informations que je transmets ci-dessus soient traitées électroniquement et utilisées pour me contacter par e-mail et téléphone par Berdin Groupe, Natilia, Villas Club, Plurial Home Expert et Plurial Novilia.</label>
					</div>
					<button 
						type="submit" 
						className="btn btn-info col-12"
						id="btnSubmit"
					>
						{ this.props.msgInSending ? "Envoi en cours..." : "Envoyer"}
					</button>
				</form>
			</div>
		)
	}
}

export default FormOne;