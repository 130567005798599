import React, { Component } from "react";
import { Parallax } from 'react-parallax';

class Slide extends Component {
	render() {
		const image = "/img/parallax.jpg";
		const parallaxStyles = {
			height: "400px"
		};
		return (
			<Parallax bgImage={ image } strength={-100}>
				<div style={ parallaxStyles }></div>
			</Parallax>
		)
	}
}

export default Slide;