import React from 'react'
import axios from 'axios'

const ProjectContext = React.createContext()
let BASE_URL = "//parc-montepillois-cormontreuil.fr/";
// if (process.env.NODE_ENV === "development") {
//   BASE_URL = "http://localhost:1323/";
// }
class ProjectProvider extends React.Component {
	state = {
    titles: [{name:"",meta_key:"",title:"",cover:""}],
    plots: [{
        "name": "",
        "slug": "",
        "number": 0,
        "size": 0,
        "front": 0,
        "price": 0,
        "comment": "",
        "state_id": 0,
        "state": {
            "name": "",
            "color": "",
            "meta_key": ""
        },
        "company_id": 0,
        "company": {
            "name": "",
            "phone": "",
            "email": "",
            "description": "",
            "logo": "",
            "site": "",
            "address_id": 0,
            "address": {
            }
        },
        "properties": null
    }],
    selectedPlot: [{}],
    properties: [{}],
    companies: [{}],
    menuActive: false,
    msgSend: false,
    msgInSending: false
  }

  constructor() {
    super()
    this.getAllProjectDatas = this.getAllProjectDatas.bind(this)
    this.findTitle = this.findTitle.bind(this)
    this.findPlot = this.findPlot.bind(this)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.contactForm = this.contactForm.bind(this)
  }

  /*
  //┐
  //│  ╔═════════════════════════════╗
  //│  ║                             ║
  //╠──╢ Init                        ║
  //│  ║                             ║
  //│  ╚═════════════════════════════╝
  //┘
  */

  toggleMenu = () => {    
    let menuState = !this.state.menuActive;
    return this.setState({
        menuActive: menuState
    });
  }

  getAllProjectDatas = () => {
		axios.post(
        `${BASE_URL}home`,
        {}
      ).then(res => {
      return this.setState({
        titles: res.data.titles,
        companies: res.data.companies,
        plots: res.data.plots,
        properties: res.data.properties,    
      })
    })
  }

  findTitle = (metaKey) => {
    let title = this.state.titles.find(function(e) {
        return e.meta_key === metaKey;
    });
    
    if (title) {
      return title
    } else {
      return {
        name:"",
        title:"",
        description:"",
        cover:"",
        meta_key:""
      }
    }
  }

  findPlot = (plotId) => {

    let id = plotId.split("-")
    let plot = this.state.plots.find(function(e) {
      // eslint-disable-next-line  
      return e.ID == id[1];
    });

    let company = this.state.companies.find(function(e) {
      return e.ID === plot.company_id;
    });
    
    plot.company = company

    if(plot.state.meta_key !== "vendu"){
      return this.setState({
        selectedPlot: plot
      })
    }
  }

  contactForm = (data) => {
    this.setState({
      msgInSending: true
    })

    axios.post(
      `${BASE_URL}contact`, 
      data
    ).then(res => {
      if(res.data.mail === "send") {
        this.setState({
          msgSend: true,
          msgInSending: false
        })
      }
    })
  }

  componentDidMount() {
  	this.getAllProjectDatas()
  } 

  render() {
    return (
      <ProjectContext.Provider
        value={{
          BASEURL: BASE_URL,
          plots: this.state.plots,
          properties: this.state.properties,
          companies: this.state.companies,
          findTitle: this.findTitle,
          findPlot: this.findPlot,
          selectedPlot: this.state.selectedPlot,
          toggleMenu: this.toggleMenu,
          menuActive: this.state.menuActive,
          contactForm: this.contactForm,
          msgSend: this.state.msgSend,
          msgInSending: this.state.msgInSending
        }}
      >
        {this.props.children}
      </ProjectContext.Provider>
    )
  }

}

const ProjectConsumer = ProjectContext.Consumer

export { ProjectProvider, ProjectConsumer }