import React, { Component } from "react";
import ReactGA from 'react-ga';

class CollabCard extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      isShow: false
    }
  }


  handleButton() {
    this.setState({ isShow: true })

    if(this.props.name.includes("Berdin")) {
      ReactGA.ga('send', 'event', 'clic-telephone', this.props.gaUrl, 'clic-telephone-Berdin');
    }

    if(this.props.name.includes("Plurial")) {
      ReactGA.ga('send', 'event', 'clic-telephone', this.props.gaUrl, 'clic-telephone-PHE');
    }
      

  }
  
  render() {
    return (
      <div className="card collab_card p-0 h100" style={{width: "18rem"}}>
        <div className="card-img-top text-center">
          <img src={ this.props.logo } alt={ this.props.name } />
        </div>
        <div className="card-body text-center">
          <p className="card-text mt-3">{this.props.description}</p>
          { this.state.isShow ? (
            <strong><p className="card-text mt-3 tel" dangerouslySetInnerHTML={{ __html: this.props.phone }}></p></strong>
          ) : (
            <button className="btn btn-info col-12" onClick={ this.handleButton.bind(this) }>Afficher le numéro</button>
          ) }
        </div>
      </div>
    )
  }
}

export default CollabCard;