import React, { Component } from "react";

class Title extends Component {
	createMarkup() {
		return {__html: 'First &middot; Second'};
	}

	hasTitle = () => {
		if(this.props.hastitle !== "no-title") {
			return (
				<div className="block_title">
					<p className="name text-uppercase position-relative">{this.props.name}</p>
					<h3 className="title">{this.props.title}</h3>					
					<p className="description" dangerouslySetInnerHTML={{ __html: this.props.description }}></p>
				</div>
			)
		} else {
			return (
				<div className="block_title">					
					<p className="description" dangerouslySetInnerHTML={{ __html: this.props.description }}></p>
				</div>
			)
		}
	}
	
	render() {
		return (
			<div>
				{this.hasTitle()}
			</div>
		)
	}
}

export default Title;