import React, { Component } from "react";
import GoogleMapReact from 'google-map-react';

import logo from '../assets/img/marker-green.svg';

const GMapContent = ({ text }) => <div style={{
                                        color: 'black',
                                        display: 'inline-flex',
                                        textAlign: 'center',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}><img src={ logo } alt={ text } /></div>;
 
class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 49.213787,
      lng: 4.036041
    },
    zoom: 15
  };
 
  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '450px', width: '100%' }} className="mb-5">
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBDFJrm4CLeN770Cm4MbmODFNEoAYupZK8" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <GMapContent
            lat={49.213787}
            lng={4.036041}
            text={'Parc Montepillois'}
          />
        </GoogleMapReact>
      </div>
    );
  }
}
 
export default SimpleMap;