import React, { Component } from "react";
import Title from "./title";
import { Link } from 'react-router-dom';
class Exemple extends Component {
	render() {
		return (
			<div className="exemple container padd">
				<div className="col-lg-10 mx-auto">
					<div className="col-lg-6 mx-auto text-center mb-5 section_title">
						<Title
							name={this.props.findTitle(this.props.section).name}
							title={this.props.findTitle(this.props.section).title}
							description={this.props.findTitle(this.props.section).description}
						/>
					</div>
					<div className="row position-relative v_ligne_height">
                            {this.props.companies.map((company, key) => {
                                return (
                                    <div key={key} className="col-6 text-center mb-4 partner">
                                        <img className="card-img-top mb-3" src={company.logo} alt={company.name} />
                                        <p className="title">{company.description}</p>
                                        {this.props.properties.map((property, key) => {
                                            return (property.company_id === company.ID) ?
                                            (
                                                <div key={key} className={`mb-4 item`}>
                                                    <div className="card gallerie_card mx-auto" style={{width: "18rem"}}>
                                                        <img className="card-img-top img-fit" src={property.cover} alt={property.name} />
                                                        <div className="card-body text-center">
                                                            <Link to={`/biens/${property.slug}`} className="btn btn-info">Voir plus</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
												<span key={key}></span>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        <div className="vertical_ligne"></div>
                    </div>
				</div>
			</div>
		)
	}
}

export default Exemple;