import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { formatNumber } from "../utils/noName";

import ReactGA from 'react-ga';

class ResaCard extends Component {

	addClass = ((el, classNameToAdd) => {
		el.className += ' ' + classNameToAdd;   
	})
	
	removeClass = ((el, classNameToRemove) => {
		var elClass = ' ' + el.className + ' ';		

		while(elClass.indexOf(' ' + classNameToRemove + ' ') !== -1){
			elClass = elClass.replace(' ' + classNameToRemove + ' ', '');
		}
		el.className = elClass;
	})

	closeModal = () => {
		let card = document.getElementsByClassName("resa_card")[0]
		this.addClass(document.getElementById('modalOverlay'), "d-none")
		this.removeClass(card, "fadeInDown")
		this.addClass(card, "fadeOutUp")
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.plot.ID !== this.props.plot.ID) {
			let card = document.getElementsByClassName("resa_card")[0]
			this.removeClass(card, "fadeOutUp")
			this.addClass(card, "fadeInDown")
			document.getElementById('modalOverlay').classList.remove('d-none')
		}
	}

	render() {
		return (
			<section>
				{ this.props.btn ? (
                    <div id="modalOverlay" onClick={ this.closeModal.bind(this) } className="modalOverlay animated"></div>
                ) : (
                    <span id="modalOverlay"></span>
                )}
				<div className={` ${this.props.position} card resa_card text-left ${ this.props.btn ? " col-lg-3 p-0 position-absolute animated  faster resa_card_map fadeInDown" : "" }`}>
					
					<div className="modal-header card-header">
						<h5 className="title"><strong>{ this.props.plot.name }</strong> { this.props.plot.number }</h5>
						{this.props.btn ? (<i onClick={ this.closeModal.bind(this) } className="close close_modal icon-cross"></i> ): (<i></i>)}
					</div>
									
					<div className="card-body">
						<div className="details">
							<p>Statut</p>					
							<p><strong>{ this.props.plot.state.name }</strong></p>
						</div>
						
						<div className="details">
							<p>Surface du terrain</p>
							<p><strong>{ this.props.plot.size } m<sup>2</sup></strong></p>
						</div>

						<div className="details">
							<p>Prix</p>					
							<p><strong>{ formatNumber(Math.round((this.props.plot.price))) } €  TTC</strong></p>
							{/* <p><small>(dont TVA 20% : { formatNumber(Math.round((this.props.plot.price * 20 / 100))) } € )</small></p> */}
						</div>

						<div className="details">
							<p>Constructeur</p>
							<img className="img-fluid" width={300} src={ this.props.plot.company.logo } alt={ this.props.plot.company.name } />
						</div>
						{this.props.btn ? (
							<div className="text-center">
								<Link to={ `/terrains/${this.props.plot.slug}` } className="btn btn-info">En savoir plus</Link>
							</div>
						) : (
							<div className="text-center">
								<a onClick={ () => ReactGA.ga('send', 'event', 'clic-telechargement', 'clic-telechargement', 'telechargement-plan') } href={ `${this.props.plot.pdf}` } target="blank" className="btn btn-info">Télécharger le plan</a>
							</div>
						)}
					
					</div>
				</div>
			</section>
			
		)
	}
}

export default ResaCard;