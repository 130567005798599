import React, { Component } from 'react';

import { ProjectProvider, ProjectConsumer } from './Front/contexts/ProjectContext'

import Header from './Front/components/header';
import { Menu } from './Front/components/menu';
import Video from './Front/components/video';
import Project from './Front/components/project';
import ProjectMap from './Front/components/project-map';
import Slide from './Front/components/slide';
import Collab from './Front/components/collab';
import Map from './Front/components/map';

import TablePlots from './Front/components/table-plots';

import Exemple from './Front/components/exemple';
import Partners from './Front/components/partners';
import FormHome from './Front/components/form-home';

import Credit from './Front/components/credit';
import { Collapse, Button } from "react-bootstrap";

import Helmet from 'react-helmet';

class Manager extends Component {
    constructor(props, context) {
        super(props, context);
    
        this.state = {
          open: false
        };
      }

    componentDidMount(){
        console.info("%c 👋 Hello, \n ✔️ Consultation. \n ✔️ Design. \n ✔️ Développement. \n 🤙 https://www.madara.io", "color:#00000;font-weight:bold;font-size:18px;")
    }

    componentDidUpdate(){
        console.log(this.state.open)
    }
    
    render() {
        return (
            <ProjectProvider>
                <Helmet>
					<title>Le Parc Montepillois | Votre maison au coeur de Cormontreuil</title>
					<meta name="description" content="" />
				</Helmet>
                <ProjectConsumer>
                    {({ findTitle, findPlot, plots, selectedPlot, properties, companies, toggleMenu, menuActive, contactForm, msgSend, msgInSending }) => {
                        return (
                            <section className="position-relative" id="top">
                                <Header 
                                    title="Home" 
                                    page="home"
                                    color="light"
                                    position="fixed_perso" 
                                    toggleMenu={ toggleMenu }
                                    back=""
                                />
                                <Menu 
                                    menuActive={ menuActive }
                                    toggleMenu={ toggleMenu }
                                />
                                <Video 
                                    menuActive={ menuActive }
                                    toggleMenu={ toggleMenu }
                                    findTitle={ findTitle }
                                    section="video"
                                />
                                <section className="light_theme padd" id="about">
                                    <Project 
                                        findTitle={ findTitle }
                                        section="about_1"
                                        direction=""                                        
                                    />
                                </section>
                                <section className="light_theme pb100">
                                    <ProjectMap
                                        findTitle={ findTitle }
                                        hastitle="no-title"
                                        section="about_2"
                                        direction="reverse" 
                                    />
                                </section>
                                <section className="dark_theme" id="resa">
                                    <Map 
                                        findTitle={ findTitle }
                                        position="right"
                                        plots={ plots }
                                        findPlot={ findPlot }
                                        selectedPlot={ selectedPlot }
                                        section="map"
                                    />	
                                    <div className="text-center mb-5">
                                        <Button className="btn btn-info btn-perso mb-5" onClick={() => this.setState({ open: !this.state.open })}>
                                            Grille de prix { this.state.open ? "↑" : "↓" }
                                        </Button>
                                    </div>
                                </section>
                                
                                <Collapse in={ this.state.open } className={ this.state.open ? `show` : null }>
                                    <section className="light_theme padd100">
                                        <TablePlots	plots={ plots } />
                                    </section>
                                </Collapse>
                                
                                <section id="houses" className="light_theme">
                                    <Exemple 
                                        findTitle={ findTitle }
                                        section="houses"
                                        companies={ companies } 
                                        properties={ properties } 
                                    />
                                </section>
                                <Slide />
                                <section id="agency" className="light_theme">
                                    <Collab 
                                        findTitle={ findTitle }
                                        section="agency"
                                        companies={ companies } 
                                    />
                                </section>	
                                <section className="medium_theme">
                                    <Partners
                                        findTitle={ findTitle }
                                        companies={ companies }
                                        section="partners"
                                    />
                                </section>
                                <section>
                                    <FormHome
                                        msgInSending={ msgInSending }
                                        msgSend={ msgSend }
                                        contactForm={ contactForm }
                                        companies={ companies }
                                    />
                                </section>
                                <section id="credit">
                                    <Credit />
                                </section>
                            </section>
                        )
                    }}
                </ProjectConsumer>
            </ProjectProvider>
        )
    }
}

export default Manager;
