import React, { Component } from "react";
// CSS
import { CSSTransitionGroup } from 'react-transition-group';
import '../animation.css';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class Menu extends Component {
	menuCustom = () => {
		if (this.props.menuActive) {
			return (
				<div key='1' className="menu">
					<div className="link">
						<AnchorLink href="#top"><img className="logo" src="/img/logo-color.svg" alt="" /></AnchorLink>
					</div>
					<div className="menu_off link d-flex align-items-center justify-content-center" onClick={this.props.toggleMenu}>
						<img src="/img/icon/close-logo.svg" alt="close" />
					</div>
					<div className="nav_custom">
						<MenuItems />
					</div>
				</div>
			)
		} else {
			return (null)
		}
	}

	overlayCustom = () => {
		if (this.props.menuActive) {
			return (<div key='1' className="overlay_perso" onClick={this.props.toggleMenu}></div>)
		} else {
			return (null)
		}
	}

	render() {
		return (
			<div className="menu-container">


				<CSSTransitionGroup
					transitionName="menu"
					transitionEnterTimeout={1200}
					transitionLeaveTimeout={500}
				>
					{this.menuCustom()}
				</CSSTransitionGroup>
			</div>
		)
	}
}

class MenuItems extends Component {
	render() {
		return (
			<ul className="link_container">
				<li className="nav-item" key="1">
					<AnchorLink offset='100' href="#about" className="link nav-link">
						À propos
                  <span></span>
					</AnchorLink>
				</li>
				<li className="nav-item" key="2">
					<AnchorLink offset='100' href="#resa" className="link nav-link">
						Les terrains
                  <span></span>
					</AnchorLink>
				</li>
				<li className="nav-item" key="4">
					<AnchorLink offset='100' href="#houses" className="link nav-link">
						Les maisons
                  <span></span>
					</AnchorLink>
				</li>
				<li className="nav-item" key="3">
					<AnchorLink offset='100' href="#agency" className="link nav-link">
						Les constructeurs
                  <span></span>
					</AnchorLink>
				</li>
				<li className="nav-item" key="3">
					<a offset='100' href="https://residenceduparc.berdin.immo/" rel="noopener noreferrer" target="_blank" className="link nav-link">
						La Résidence du Parc
            <span></span>
					</a>
				</li>
				<li className="nav-item" key="5">
					<AnchorLink offset='100' href="#contact" className="link nav-link">
						Contact
                  <span></span>
					</AnchorLink>
				</li>
			</ul>
		)
	}
}

export { Menu, MenuItems };