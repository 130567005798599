import React, { Component } from "react";
import FormOne from "./form-one";
import AnchorLink from 'react-anchor-link-smooth-scroll'

import CollabCard from "./collab-card";

class FormHome extends Component {

    render() {
        return (
            <footer className="footer dark_theme padd" id="contact">
                <div className="container">
                    <div className="col-lg-12 mx-auto">
                        <div className="row">
                            <div className="col-lg-6 p-0 pr-lg-4">
                                <AnchorLink href="#top" className="anchor-scroll"><img src="/img/logo.svg" alt="" /></AnchorLink>
                                {/* <p>T.    <strong>+33 (0) 3 26 XX XX XX</strong></p> */}
                                <div className="row padd40">
                                    { this.props.companies.map((company, key) => {
                                        return (
                                            <div key={key} className="col-6">
                                                <CollabCard 
                                                    logo={ company.logo }
                                                    name={ company.name }
                                                    description={ company.description }
                                                    site={ company.site }
                                                    phone={ company.phone }
                                                    gaUrl="clic-telephone-HP"
                                                />
                                            </div>
                                        )
                                    }) }
                                </div>
                            </div>
                            <div className="col-lg-6 p-0">
                                <div className="form_title mb-4">
                                    <p><strong>Contactez-nous</strong></p>
                                    <p>en remplissant le formulaire ci-dessous</p>
                                </div>
                                <FormOne 
                                    msgInSending={ this.props.msgInSending }
                                    msgSend={ this.props.msgSend }
                                    contactForm={ this.props.contactForm }
                                    companies={ this.props.companies }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default FormHome;