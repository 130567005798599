import React, { Component } from "react";
import {Alert} from "react-bootstrap"
import ReactGA from 'react-ga';

class FormResa extends Component {

	handleSubmit(event) {
		event.preventDefault();
		
		if(this.props.plot !== undefined) {
			ReactGA.ga('send', 'pageview', '/demande-de-contact-terrain-ok');
		} else {
			ReactGA.ga('send', 'pageview', '/demande-de-contact-modele-maison-ok');
		}

		const form = event.target;
    	const data = new FormData(form);

		this.props.contactForm(data)
		this.contactForm.reset();
	}
	
	render() {
		return (
			<div className="form-resa padd40">

				{ this.props.msgSend ? 
					(<Alert bsStyle="success">
						Votre message a bien été envoyé aux équipes. Nous revenons vers vous très prochainement.
					</Alert> ) : (null) }

				<form onSubmit={ this.handleSubmit.bind(this) } ref={(el) => this.contactForm = el} name="contactForm">
					{this.props.plot !== undefined ? (
                        <div>
                            <input type="hidden" name="CompanyID" defaultValue={ this.props.plot.company_id } />
                            <input type="hidden" name="PlotID" defaultValue={ this.props.plot.ID } />
                        </div>
                    ) : (<input type="hidden" name="CompanyID" defaultValue={ this.props.company.ID } />)}
					<div className="form-row">
						<div className="form-group col-md-6">
							<input 
								type="text" 
								required 
								className="form-control" 
								name="firstname" 
								placeholder="Prénom *" 
							/>
						</div>
						<div className="form-group col-md-6">
							<input 
								type="text" 
								required 
								className="form-control" 
								name="lastname" 
								placeholder="Nom *" 
							/>
						</div>
					</div>
					<div className="form-row">
						<div className="form-group col-md-6">
							<input 
								type="email" 
								required 
								className="form-control" 
								name="email" 
								placeholder="Email *" 
							/>
						</div>
						<div className="form-group col-md-6">
							<input 
								type="tel"
								className="form-control" 
								name="phone" 
								placeholder="Téléphone" 
							/>
						</div>
					</div>
					<div className="form-row">
						{this.props.plot !== undefined ? (
							<div className="form-group col-md-12">
								<input 
									type="text" 
									className="form-control"
									name="plot_name" 
									readOnly
									defaultValue={`${this.props.plot.name} n° ${this.props.plot.number}`} 
								/>
							</div>
						) : (null)}
					</div>
					<div className="form-group">
						<textarea 
							className="form-control"
							name="message" 
							placeholder="Message" 
							rows="6"></textarea>
					</div>
					<div className="custom-control custom-checkbox mb-2">
						<input id="policy" required type="checkbox" className="custom-control-input" name="policy"/>
						<label className="custom-control-label policy" htmlFor="policy">J’accepte que les informations que je transmets ci-dessus soient traitées électroniquement et utilisées pour me contacter par e-mail et téléphone par Berdin Groupe, Natilia, Villas Club, Plurial Home Expert et Plurial Novilia.</label>
					</div>

					<button 
						type="submit" 
						className="btn btn-info col-12"
						id="btnSubmit"
					>
						{ this.props.msgInSending ? "Envoi en cours..." : "Envoyer"}
					</button>

				</form>
			</div>
		)
	}
}

export default FormResa;