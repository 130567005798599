import React, { Component } from "react";
import Title from "./title";
import CollabCard from "./collab-card";

class Collab extends Component {
	render() {
		return (
			<div className="collab container item padd">
				<div className="col-12">
					<div className="row">
						<div className="description col-lg-4 offset-lg-1 d-flex align-items-center mb-5 mb-lg-0">
							<Title							
								name={this.props.findTitle(this.props.section).name}
								title={this.props.findTitle(this.props.section).title}
								description={this.props.findTitle(this.props.section).description}
							/>
						</div>
						<div className="col-lg-6">
							<div className="row">
								{this.props.companies.map((company, key) => {
									return (
										<div key={key} className="col-6">
											<CollabCard 
												logo={company.logo}
												name={company.name}
												description={company.description}
												site={company.site}
												phone={company.phone}
												gaUrl="clic-telephone-HP"
											/>
										</div>
									)
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default Collab;
