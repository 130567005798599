import React, { Component } from "react";
// import Title from "./title";
import ResaCard from "./resa-card";
import ReactSVG from 'react-svg';
import "./map.css";

class Map extends Component {
	
	changePlotsClass = (svg) => {
        let thiis = this
        if (document.getElementsByClassName("plot").length > 0 ) {
            let classPath = Array.from((document.getElementsByClassName("plot")))
            if (classPath.length > 0) {
				// eslint-disable-next-line
				classPath.map((item, key) => {
                    let id = item.id.split("-")
                    let plot = thiis.props.plots.find(function(e) {
                        // eslint-disable-next-line  
                        return e.ID == id[1];
                    });                
                    if (plot !== undefined) {
                        if (plot.state.color.length) {
                            return item.classList.add(plot.state.color)
                        }
                    }
                })
            }
        }
    }

	render() {
		return (
			<div className="map padd">				
				<div className="col-lg-4 col-10 col-md-8 text-center mx-auto section_title">
					<div className="block_title">
						<p className="name text-uppercase position-relative">{this.props.findTitle(this.props.section).name}</p>
						<h3 className="title">{this.props.findTitle(this.props.section).title}</h3>
						<p className="description"><strong>{this.props.findTitle(this.props.section).description}</strong></p>
					</div>
				</div>
				<div className="text-center container mt-5 position-relative">
					<ReactSVG 
						src="/img/map.svg"
						evalScripts="always"						
						renumerateIRIElements={false}
						onInjected={svg => {
							this.changePlotsClass(svg)
						}}
						onClick={(event) => {
							let plotId = event.target.id
							if(plotId.includes("plot-")){
								this.props.findPlot(plotId)
							}
						}}
					/>
						
					{this.props.selectedPlot.name ? (
						<ResaCard 
							btn={true} 
							plot={this.props.selectedPlot} 
							position={this.props.position}
						/>
					) : (null)}
					<div className="legend">
						<img src="/img/legend.svg" alt=""/>
					</div>
	
				</div>
			</div>			
		)
	}
}

export default Map;