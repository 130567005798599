import React, { Component } from "react";

class ShortTitle extends Component {
	render() {
		return (
			<div className="block_short_title">
				<h3 className="title">Modèles proposés pour ce terrain.</h3>
			</div>
		)
	}
}

export default ShortTitle;