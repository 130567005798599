import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { formatNumber } from "../utils/noName";
class TablePlots extends Component {    
    
    constructor(props) {
        super(props);
        // this.state = { data: [] };
        this.onSort = this.onSort.bind(this)
    }

    componentDidMount() {
        // this.setState( { data: this.props.plots } )
    }

    onSort(sortKey){
        // const data = this.state.data;
        // data.sort((a,b) => a[sortKey].localeCompare(b[sortKey]))
        // this.setState( {data} )

        // console.log(this.state.data)
    }

    render() {
        
        return (
            <Table responsive striped hover className="container">
                <thead>
                    <tr>
                        <th onClick={e => this.onSort('number')}>Terrain</th>
                        <th onClick={e => this.onSort('company.name')}>Constructeur</th>
                        <th className="price text-right" onClick={e => this.onSort('price')}>Prix</th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.plots.map( (plot, key) => 
                        plot.state.meta_key !== "vendu" ?
                            <tr className="pointer" key={ key } onClick={() => window.location = `/terrains/${plot.slug}` }>
                                <td className="align-middle"><span className="dotResa" style={{ color: plot.state.color }}>●</span> Terrain n°{ plot.number } de { plot.size }m<sup>2</sup></td>                                    
                                <td>
                                    <img src={ plot.company.logo } alt={ plot.company.name } width={100} />
                                </td>
                                <td className="align-middle text-right">{ formatNumber(Math.round(plot.price)) } €  TTC</td>
                            </tr>
                        : null
                     )}
                </tbody>
            </Table>
        )
    }
}

export default TablePlots;